import { createRouter, createWebHistory } from 'vue-router'
import axios from 'axios'
import Utils from '../js/utils'
import { sha256 } from 'sha.js'

const routes = [
  {
    path: '/',
    name: 'MainMenu',
    component: () => import('@/pages/MainMenu.vue'),
  },
  {
    path: '/orgs',
    name: 'Orgs',
    // асинхронная (lazy) загрузка
    component: () => import('@/pages/Orgs.vue'),
  },
  {
    path: '/org/:id?', // ? - необязательный
    name: 'OrgEdit',
    component: () => import('@/pages/OrgEdit.vue'),
  },
  {
    path: '/departments',
    name: 'Departments',
    // асинхронная (lazy) загрузка
    component: () => import('@/pages/Departments.vue'),
  },
  {
    path: '/department/:id?', // ? - необязательный
    name: 'DepartmentEdit',
    component: () => import('@/pages/DepartmentEdit.vue'),
  },
  {
    path: '/department-view/:id',
    name: 'DepartmentView',
    component: () => import('@/pages/DepartmentEdit.vue'),
  },
  {
    path: '/participants',
    name: 'Participants',
    component: () => import('@/pages/Participants.vue'),
  },
  {
    path: '/participant-view/:id',
    name: 'ParticipantView',
    component: () => import('@/pages/ParticipantEdit.vue'),
  },
  {
    path: '/participant/:id?', // ? - необязательный
    name: 'ParticipantEdit',
    component: () => import('@/pages/ParticipantEdit.vue'),
  },
  {
    path: '/manufacturers',
    name: 'Manufacturers',
    component: () => import('@/pages/Manufacturers.vue'),
  },
  {
    path: '/manufacturer-view/:id',
    name: 'ManufacturerView',
    component: () => import('@/pages/ManufacturerEdit.vue'),
  },
  {
    path: '/manufacturer/:id?', // ? - необязательный
    name: 'ManufacturerEdit',
    component: () => import('@/pages/ManufacturerEdit.vue'),
  },
  {
    path: '/change-password',
    name: 'ChangePassword',
    component: () => import('@/pages/ChangePassword.vue'),
  },
  {
    path: '/medical-devices',
    name: 'MedicalDevices',
    component: () => import('@/pages/MedicalDevices.vue'),
  },
  {
    path: '/medical-device/:id?', // ? - необязательный
    name: 'MedicalDeviceEdit',
    component: () => import('@/pages/MedicalDeviceEdit.vue'),
  },
  {
    path: '/medical-device-view/:id',
    name: 'MedicalDeviceView',
    component: () => import('@/pages/MedicalDeviceEdit.vue'),
  },
  {
    path: '/equipments',
    name: 'Equipments',
    component: () => import('@/pages/Equipments.vue'),
  },
  {
    path: '/equipment/:id?', // ? - необязательный
    name: 'EquipmentEdit',
    component: () => import('@/pages/EquipmentEdit.vue'),
  },
  {
    path: '/equipment-view/:id',
    name: 'EquipmentView',
    component: () => import('@/pages/EquipmentEdit.vue'),
  },
  {
    path: '/requests',
    name: 'Requests',
    component: () => import('@/pages/Requests.vue'),
  },
  {
    path: '/fast-requests',
    name: 'FastRequests',
    component: () => import('@/pages/Requests.vue'),
  },
  {
    path: '/repair-requests',
    name: 'RepairRequests',
    component: () => import('@/pages/Requests.vue'),
  },
  {
    path: '/diagnosis-requests',
    name: 'DiagnosisRequests',
    component: () => import('@/pages/Requests.vue'),
  },
  {
    path: '/service-requests',
    name: 'ServiceRequests',
    component: () => import('@/pages/Requests.vue'),
  },
  {
    path: '/request-view/:id',
    name: 'RequestView',
    component: () => import('@/pages/RequestEdit.vue'),
  },
  {
    path: '/request/:id?', // ? - необязательный
    name: 'RequestEdit',
    component: () => import('@/pages/RequestEdit.vue'),
  },
  {
    path: '/dashboard',
    name: 'Dashboards',
    component: () => import('@/pages/Dashboard.vue'),
  },
  {
    path: '/reports',
    name: 'Reports',
    component: () => import('@/pages/Reports.vue'),
  },
  {
    path: '/schedule-report',
    name: 'EngineersScheduleReports',
    component: () => import('@/pages/EngineersScheduleReport.vue'),
  },
  {
    path: '/pdf-reports',
    name: 'PdfReports',
    component: () => import('@/pages/PdfReports.vue'),
  },
  {
    path: '/tasks',
    name: 'Tasks',
    component: () => import('@/pages/Tasks.vue'),
  },
  {
    path: '/task/:id?', // ? - необязательный
    name: 'TaskEdit',
    component: () => import('@/pages/TaskEdit.vue'),
  },
  {
    path: '/task-view/:id',
    name: 'TaskView',
    component: () => import('@/pages/TaskEdit.vue'),
  },
  {
    path: '/:catchAll(.*)',
    name: 'Error404',
    component: () => import('@/pages/Error404.vue'),
  },
  {
    path: '/roles',
    name: 'Roles',
    component: () => import('@/pages/Roles.vue'),
  },
  {
    path: '/role/:id?',
    name: 'RoleEdit',
    component: () => import('@/pages/RoleEdit.vue'),
  },
  {
    path: '/engineers',
    name: 'Engineers',
    component: () => import('@/pages/Engineers.vue'),
  },
  {
    path: '/engineer-view/:id?',
    name: 'EngineerView',
    component: () => import('@/pages/ParticipantEdit.vue'),
  },
  {
    path: '/contracts',
    name: 'Contracts',
    component: () => import('@/pages/Contracts.vue'),
  },
  {
    path: '/contract/:id?',
    name: 'ContractEdit',
    component: () => import('@/pages/ContractEdit.vue'),
  },
  {
    path: '/contract-view/:id?',
    name: 'ContractView',
    component: () => import('@/pages/ContractEdit.vue'),
  },
  {
    path: '/engineer/:id?',
    name: 'EngineerEdit',
    component: () => import('@/pages/ParticipantEdit.vue'),
  },
  {
    path: '/calendarday/:id?',
    name: 'CalendarDayEdit',
    component: () => import('@/pages/CalendarDayEdit.vue'),
  },
  {
    path: '/calendardays',
    name: 'CalendarDays',
    component: () => import('@/pages/CalendarDays.vue'),
  },
  {
    path: '/organization-contact/:contactid?',
    name: 'OrganizationContactEdit',
    component: () => import('@/pages/OrganizationContactEdit.vue'),
  },
  {
    path: '/organization-contact-view/:contactid?',
    name: 'OrganizationContactView',
    component: () => import('@/pages/OrganizationContactEdit.vue'),
  },
  {
    path: '/organization-contacts',
    name: 'OrganizationContacts',
    component: () => import('@/pages/OrganizationContacts.vue'),
  },
  {
    path: '/contragent/:id?',
    name: 'ContragentEdit',
    component: () => import('@/pages/ContragentEdit.vue'),
  },
  {
    path: '/contragent-view/:id?',
    name: 'ContragentView',
    component: () => import('@/pages/ContragentEdit.vue'),
  },
  {
    path: '/contragents',
    name: 'Contragents',
    component: () => import('@/pages/Contragents.vue'),
  },
  {
    path: '/engineer/:id/schedules/:setDate?',
    name: 'EngineerSchedules',
    component: () => import('@/pages/EngineerSchedules.vue'),
  },
  {
    path: '/engineer/:id/schedule/:setDate',
    name: 'EngineerScheduleEdit',
    component: () => import('@/pages/EngineerScheduleEdit.vue'),
  },
  {
    path: '/activities',
    name: 'Activities',
    component: () => import('@/pages/Activities.vue'),
  },
  {
    path: '/activity/:id?',
    name: 'ActivityEdit',
    component: () => import('@/pages/ActivityEdit.vue'),
  },
  {
    path: '/activity-view/:id?',
    name: 'ActivityView',
    component: () => import('@/pages/ActivityEdit.vue'),
  },
  {
    path: '/activity-reminder',
    name: 'ActivityReminderCreate',
    component: () => import('@/pages/ActivityReminderCreate.vue'),
  },
  {
    path: '/reminders',
    name: 'Reminders',
    component: () => import('@/pages/Reminders.vue'),
  },
  {
    path: '/reminder/:id?',
    name: 'ReminderEdit',
    component: () => import('@/pages/ReminderEdit.vue'),
  },
  {
    path: '/reminder-view/:id?',
    name: 'ReminderView',
    component: () => import('@/pages/ReminderEdit.vue'),
  },
  {
    path: '/notices',
    name: 'Notices',
    component: () => import('@/pages/Notices.vue'),
  },
  {
    path: '/equipment-import',
    name: 'EquipmentImport',
    component: () => import('@/pages/EquipmentImport.vue'),
  },
  {
    path: '/equipment-report',
    name: 'EquipmentReports',
    component: () => import('@/pages/EquipmentReport.vue'),
  },
  {
    path: '/equipment-log',
    name: 'EquipmentLogs',
    component: () => import('@/pages/EquipmentLog.vue'),
  },
  {
    path: '/log-owners',
    name: 'LogOwners',
    // асинхронная (lazy) загрузка
    component: () => import('@/pages/LogOwners.vue'),
  },
  {
    path: '/log-owner/:id?',
    name: 'LogOwnerEdit',
    component: () => import('@/pages/LogOwnerEdit.vue'),
  },
]

function getInfo(props, ret) {
  return axios
    .get(process.env.API_SERVER + '/participant/me')
    .then((response) => {
      if (response && response.data) {
        let d = response.data
        if (d.lastName) {
          props.$loginInfo.userName = props.$userName =
            d.lastName +
            (d.firstName ? ' ' + d.firstName.substr(0, 1).toUpperCase() + '.' : '') +
            (d.middleName ? ' ' + d.middleName.substr(0, 1).toUpperCase() + '.' : '')
        }
        props.$organization = d.organization || null;
        return axios.get(process.env.API_SERVER + '/user/me')
      } else
        return null;
    })
    .then(({ data }) => {
      if (data && data.prtId) {
        props.$prtId = data.prtId

        if (!props.$userName)
          props.$loginInfo.userName = props.$userName = data.username
        props.$isSuperAdmin = data.admin
        props.$permissions = data.permissions
        props.$hasOrgAuthority = (orgId, needPerms) =>
          Utils.sysHasAuthority(data.admin, data.permissions, orgId, needPerms)
        props.$hasAnyAuthority = (needPerms) =>
          Utils.sysHasAuthority(data.admin, data.permissions, null, needPerms)
        props.$loginInfo.authorized = true;

        return ret;
      } else
        return false;
    })
    .catch(error =>  {
      console.log('Error in token: ' + error);

      return false;
    })
}

function getRedirectUri() {
  let url = window.location.origin + import.meta.env.BASE_URL;
  if (!url.endsWith('/'))
    url += '/';
  url += 'code';
  return url;
}

// GENERATING CODE VERIFIER
function dec2hex(dec) {
  return ("0" + dec.toString(16)).substr(-2);
}

function generateCodeVerifier() {
  var array = new Uint32Array(56 / 2);
  window.crypto.getRandomValues(array);
  return Array.from(array, dec2hex).join("");
}

function base64urlencode(a) {
  var str = "";
  var bytes = new Uint8Array(a);
  var len = bytes.byteLength;
  for (var i = 0; i < len; i++) {
    str += String.fromCharCode(bytes[i]);
  }
  return btoa(str)
    .replace(/\+/g, "-")
    .replace(/\//g, "_")
    .replace(/=+$/, "");
}

async function beforeEach(app, to, from) {
  if (to.path === '/code' && to.query.code != null) {
    let payload = {
      'grant_type': 'authorization_code',
      'code': to.query.code,
      'redirect_uri': getRedirectUri(),
      'client_id': import.meta.env.VITE_CLIENT_NAME
    };

    if (sessionStorage.getItem('code-verifier')) {
      let codeVerifier = sessionStorage.getItem('code-verifier');
      sessionStorage.removeItem('code-verifier');
      payload['code_verifier'] = codeVerifier;
    }

    const ret = await axios.post('/oauth2/token', payload, {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                }
            }
    )
    .then((response) => {
      if (response && response.data) {
        // получаем токены, кладем access token в LocalStorage
        localStorage.setItem('atk', response.data["access_token"]);
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('atk')

        return getInfo(app.config.globalProperties, {name: 'MainMenu', replace: true});
      } else {
        console.log('Empty response');
        return false;
      }
    })
    .catch(error =>  {
      console.log('Error in token: ' + error);
      return false;
    });

    if (!ret) {
      window.location = '/login';
    }
    return ret;
  } else if (to.path.startsWith('/login') || to.path.startsWith('/oauth2')) {
    return true;
  } else if (!app.config.globalProperties.$prtId) {
    if (localStorage.getItem('atk')) {
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('atk')

      const ret = await getInfo(app.config.globalProperties, true);
      if (!ret) {
        window.location = '/login';
      }
      return ret;
    } else {
      let codeVerifier = generateCodeVerifier();
      let hash = new sha256().update(codeVerifier).digest();
      let hashedCodeVerifier = base64urlencode(hash);

      sessionStorage.setItem('code-verifier', codeVerifier);

      let requestParams = new URLSearchParams({
        response_type: 'code',
        client_id: import.meta.env.VITE_CLIENT_NAME,
        redirect_uri: getRedirectUri(),
        code_challenge: hashedCodeVerifier,
        code_challenge_method: 'S256'
      });
      const url = import.meta.env.VITE_LOGIN_URL + requestParams;

      window.location = url;
      return false;
    }
  } else {
    return true;
  }
}

export const getRouter = (app) => {
  const router = createRouter({
    history: createWebHistory(import.meta.env.BASE_URL),
    routes,
  });

  router.beforeEach(async (to, from) => {return beforeEach(app, to, from)});

  return router;
}
