<template>
  <b-card-footer>
    <b-form-group label-cols-lg="2"
                  label-class="text-lg-right"
                  v-show="messages.length !== 0">
      <b-alert
        ref="alertRef"
        :show="messages.length !== 0"
        variant="danger"
        class="w-100"
        dismissible>
        <ul>
          <li v-for="msg in messages" :key="msg">{{ msg }}</li>
        </ul>
        <ul v-show="empties.length!=0" class="ul-empty">
          <li v-for="empty in empties" :key="empty">{{ empty }}</li>
        </ul>
      </b-alert>
    </b-form-group>

    <b-form-group label-cols-lg="2" class="text-right mb-0">
      <b-button variant="primary" :disabled="isLoading" v-if="!isView" @click="save">Сохранить</b-button>
      <b-button variant="secondary" @click="cancel">{{isView ? 'Назад' : 'Отмена'}}</b-button>
    </b-form-group>
  </b-card-footer>
</template>

<script>
export default {
  name: 'EditPageFooter',
  props: {
    // признак загрузки
    isLoading: {
      type: Boolean,
      default: false
    },
    // признак открытия станицы на просмотр
    isView: {
      type: Boolean,
      default: false
    },

    // список ошибок для отображения
    messages: {
      type: Array,
      default: () => []
    },
    // список незаполненных элементов
    empties: {
      type: Array,
      default: () => []
    },
    showErrors: {
      type: Boolean,
      default: false
    },

    // отбработчик сохранения
    save: {
      type: Function,
      required: true
    },
    // отбработчик отмены
    cancel: {
      type: Function,
      required: true
    }
  }
};
</script>