<template>
  <div id="app" v-if="isShow">
    <responsive-menu :menuItems="menuItems" :login="this.$loginInfo.userName"> </responsive-menu>
    <div class="container-fluid" id="tpl_content">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
const ResponsiveMenu = () => import('@/components/ResponsiveMenu.vue');
import Utils from './js/utils'

axios.interceptors.response.use(
  function (response) {
    return response
  },
  function (error) {
    if (error.config.url === '/oauth2/token') {
      return;
    }

    if (!error.response) {
      console.log(error)
      alert('Сервер недоступен')
    } else if (error.response.status === 401) {
      localStorage.removeItem('atk')
      window.location = '/login'
    } else if (error.response.status === 403) {
      alert('Недостаточно прав доступа')
      window.location = '/'
    } else if (error.response.status !== 400) {
      alert('Произошла непредвиденная ошибка, повторите попытку позже')
      if (window.location.hash !== '#/') {
        window.location = '/'
      }
    }

    return Promise.reject(error)
  },
)

export default {
  name: 'app',
  components: { ResponsiveMenu },
  data() {
    return {
      isShow: false,
      menuItems: [],
    }
  },
  watch: {
    '$loginInfo.authorized': function() {
      if (!this.$prtId)
        return

      if (this.$isSuperAdmin) {
        this.menuItems.push({ name: 'Org', title: 'Организации', group: 'row1' })
      }
      if (this.$hasAnyAuthority('ORG')) {
        this.menuItems.push({ name: 'Department', title: 'Подразделения', group: 'row1' })
      }
      if (this.$hasAnyAuthority('PRT')) {
        this.menuItems.push({ name: 'Participant', title: 'Участники', group: 'row1' })
      }
      if (this.$hasAnyAuthority('ENG')) {
        this.menuItems.push({ name: 'Engineer', title: 'Инженеры', group: 'row1' })
      }
      if (this.$hasAnyAuthority('CALEND')) {
        this.menuItems.push({
          name: 'CalendarDay',
          title: 'Производственный календарь',
          group: 'row1',
        })
      }

      this.menuItems.push({ name: 'Manufacturer', title: 'Производители', group: 'dict' })
      this.menuItems.push({
        name: 'MedicalDevice',
        title: 'Медицинские изделия',
        group: 'dict',
      })

      if (this.$isSuperAdmin) {
        this.menuItems.push({ name: 'Role', title: 'Роли', group: 'dict' })
      }
      if (this.$hasAnyAuthority('EQ')) {
        this.menuItems.push({ name: 'Equipment', title: 'Оборудование/работы', group: 'row2' })
      }
      if (
        this.$isSuperAdmin ||
        (this.$organization && this.$hasOrgAuthority(this.$organization.id, 'CONTRAGENT'))
      ) {
        this.menuItems.push({ name: 'Contragent', title: 'Контрагенты', group: 'row2' })
      }
      if (this.$hasAnyAuthority('CONTR')) {
        this.menuItems.push({ name: 'Contract', title: 'Договоры', group: 'row2' })
      }
      if (this.$hasAnyAuthority('CONTACT_ORG')) {
        this.menuItems.push({ name: 'OrganizationContact', title: 'Контакты', group: 'row2' })
      }
      if (this.$hasAnyAuthority('REQ')) {
        this.menuItems.push({ name: 'Request', title: 'Все заявки', group: 'row3' })
      }
      if (this.$hasAnyAuthority('REQ')) {
        this.menuItems.push({ name: 'FastRequest', title: 'Быстрые заявки', group: 'row3' })
      }
      if (this.$hasAnyAuthority('REQ_EXT')) {
        this.menuItems.push({ name: 'RepairRequest', title: 'Заявки на ремонт', group: 'row3' })
      }
      if (this.$hasAnyAuthority('REQ_EXT')) {
        this.menuItems.push({
          name: 'DiagnosisRequest',
          title: 'Заявки на диагностику',
          group: 'row3',
        })
      }
      if (this.$hasAnyAuthority('REQ_EXT')) {
        this.menuItems.push({
          name: 'ServiceRequest',
          title: 'Заявки на регламентное обслуживание',
          group: 'row3',
        })
      }
      if (this.$hasAnyAuthority(['TASK', 'TASK_ENG'])) {
        this.menuItems.push({ name: 'Task', title: 'Задачи', group: 'row3' })
      }
      if (this.$hasAnyAuthority(['ACTIVITY', 'ACTIVITY_OWN'])) {
        this.menuItems.push({ name: 'Activitie', title: 'Активности', group: 'row3' })
      }
      if (this.$hasAnyAuthority(['REMIND', 'REMIND_OWN'])) {
        this.menuItems.push({ name: 'Reminder', title: 'Напоминания', group: 'row3' })
      }
      //this.menuItems.push({ name: 'Dashboard', title: 'Dashboard', group: 'reports' })
      if (this.$hasAnyAuthority('REP_R1')) {
        this.menuItems.push({
          name: 'Report',
          title: 'Заявки по оборудованию',
          group: 'reports',
        })
      }
      if (this.$hasAnyAuthority('REP_R7')) {
        this.menuItems.push({
          name: 'EngineersScheduleReport',
          title: 'Расписание инженеров',
          group: 'reports',
        })
      }
      if (this.$hasAnyAuthority('REP_R8')) {
        this.menuItems.push({
          name: 'EquipmentReport',
          title: 'Отчет по оборудованию',
          group: 'reports',
        })
      }
      if (this.$hasAnyAuthority(['REP_R2', 'REP_R3', 'REP_R4', 'REP_R5', 'REP_R6'])) {
        this.menuItems.push({ name: 'PdfReport', title: 'Отчеты PDF', group: 'reports' })
      }
      this.menuItems.push({ name: 'EquipmentLog', title: 'Логи оборудования', group: 'logs' })
      this.menuItems.push({
        name: 'LogOwner',
        title: 'Организации-собственники лог-файлов',
        group: 'logs',
      })

      this.isShow = true
    }
  },
}
</script>
