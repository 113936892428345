import { createApp } from '@vue/compat'
import { createPinia } from 'pinia'
import BootstrapVue from 'bootstrap-vue'

import App from './App.vue'
import { getRouter } from './router'
import EditPageFooter from '@/components/EditPageFooter.vue'
import Utils from './js/utils'
import { reactive } from 'vue'

import './scss/font-awesome.min.css'
import './scss/styles.scss'
import '@vuepic/vue-datepicker/dist/main.css'

const app = createApp(App)

app.config.globalProperties.$fromEngineers = false
app.config.globalProperties.$engineersRoute = null
app.config.globalProperties.$permissions = []
app.config.globalProperties.$isSuperAdmin = false
app.config.globalProperties.$prtId = null
app.config.globalProperties.$organization = null
app.config.globalProperties.$dictCache = {}
app.config.globalProperties.$getDict = (name) =>
  Utils.sysGetDict('dictionary', app.config.globalProperties.$dictCache, name)
app.config.globalProperties.$enumCache = {}
app.config.globalProperties.$getEnum = (name) =>
  Utils.sysGetDict('enum', app.config.globalProperties.$enumCache, name)
app.config.globalProperties.$hasOrgAuthority = null
app.config.globalProperties.$hasAnyAuthority = null

app.config.globalProperties.$userName = null

app.config.globalProperties.$loginInfo = reactive({
  userName: null,
  authorized: false,
})

app.use(createPinia())

const router = getRouter(app)
app.use(router)

app.use(BootstrapVue, {
  BModal: {
    cancelTitle: 'Отмена',
    headerCloseLabel: 'Закрыть',
  },
})

app.component('EditPageFooter', EditPageFooter)

app.mount('#app_root')
