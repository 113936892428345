import moment from 'moment'
import _, { isObject } from 'lodash'
import axios from 'axios'
import contentDisposition from 'content-disposition'
import iconv from 'iconv-lite'

const Utils = {
  doRequest(method, url, params, headers) {
    /* , validateStatus */
    headers = headers || {}
    method = method.toUpperCase()

    const req = new URLSearchParams(
      _.omitBy(params, (x) => !x),
      null,
      null,
      { encodeURIComponent: encodeURIComponent },
    )

    return axios({
      method: method,
      url: process.env.API_SERVER + '/' + url + (method === 'GET' && params ? '?' + req : ''),
      data: method === 'GET' ? null : params,
      headers: headers,
    })
  },
  // скачивание файлов с авторизацией через axios
  downloadFile(url, filename, showError) {
    axios
      .get(url, { responseType: 'blob' })
      .then((response) => {
        const blob = response.data
        const cd = response.headers['content-disposition']
        if (!filename && cd) {
          const userAgent = window.navigator.userAgent
          if (userAgent.indexOf('MSIE') !== -1 || userAgent.indexOf('Trident') !== -1) {
            // IE
            if (cd.indexOf('attachment') !== -1) {
              const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
              const matches = filenameRegex.exec(cd)
              if (matches != null && matches[1]) {
                filename = matches[1].replace(/['"]/g, '')
              }
            }
          } else {
            const cdInfo = contentDisposition.parse(cd)
            if (cdInfo.type === 'attachment' && cdInfo.parameters['filename']) {
              let filenameBuf = new Buffer(cdInfo.parameters['filename'], 'binary')
              filenameBuf = new Buffer(iconv.decode(filenameBuf, 'iso8859-1'), 'binary')
              filename = filenameBuf.toString('utf-8')
            }
          }
        }

        if (window.navigator.msSaveOrOpenBlob) {
          if (filename) {
            window.navigator.msSaveOrOpenBlob(blob, filename)
          } else {
            window.navigator.msSaveOrOpenBlob(blob)
          }
        } else {
          const objectUrl = window.URL.createObjectURL(blob)
          if (filename) {
            let anchor = document.createElement('a')
            document.body.appendChild(anchor)
            anchor.href = objectUrl
            anchor.target = '_blank'
            anchor.download = filename
            anchor.click()
          } else {
            window.open(objectUrl)
          }
          window.URL.revokeObjectURL(objectUrl)
        }
      })
      .catch((err) => {
        console.log(err)
        if (showError && err.response.data) {
          err.response.data.text().then(function (result) {
            let errObj = JSON.parse(result)
            if (errObj && errObj.messages) {
              alert(errObj.messages[0].description)
            }
          })
        }
      })
  },

  toDate(str, format = 'YYYY-MM-DD') {
    if (!moment(str, format, true).isValid()) {
      return null
    }
    let date = moment(str, format).toDate()
    return date
  },
  fromDate(date, format = 'DD.MM.YYYY') {
    if (date == null) {
      return null
    }
    return moment(date).format(format)
  },
  toODBCString(str, format = 'DD.MM.YYYY HH:mm') {
    return this.fromDate(this.toDate(str, 'YYYY-MM-DDTHH:mm:ss'), format)
  },
  onStatus500(vm, status) {
    if (status === 500) {
      vm.messages = []
      vm.messages.push('Произошла непредвиденная ошибка')
      vm.$refs.alertRef.dismissed = false
    }
    return true // т.е. всегда resolve
  },

  list2enumNames(list) {
    if (!(list && list.length > 0)) {
      return null
    }
    return _.map(list, 'enumName')
  },

  list2ids(list) {
    if (!(list && list.length > 0)) {
      return null
    }
    return _.map(list, 'id')
  },

  formatNumber(val, prec) {
    val = val.replace(/[^0-9.,]/g, '').replace(/,/g, '.')
    if (val.indexOf('.') !== -1) {
      val = val.split('.')
      let frac = val.slice(1).join('')
      if (frac) {
        frac = frac.substr(0, prec)
      }
      val = val[0] + '.' + frac
    }
    return val
  },
  // системная функция для проверки прав
  sysHasAuthority(isAdmin, userPerms, orgId, needPerms) {
    if (isAdmin) {
      return true
    }
    needPerms = Array.isArray(needPerms) ? needPerms : [needPerms]
    for (let u of userPerms) {
      for (let n of needPerms) {
        if (orgId && u === n + ':' + orgId) {
          return true
        } else if (!orgId && u && u.indexOf(n + ':') === 0) {
          return true
        }
      }
    }
    return false
  },
  // системная функция запроса справочника
  sysGetDict(base, cache, name) {
    if (cache[name]) {
      return cache[name]
    }

    return this.doRequest('GET', base + '/' + name).then((res) => {
      cache[name] = res.data
      return res.data
    })
  },

  isTime(val) {
    return /^\d{2}:\d{2}$/.test(val)
  },

  isTimePickerObj(obj) {
    if (!isObject(obj) || !Object.hasOwn(obj, 'hours') || !Object.hasOwn(obj, 'minutes'))
      return false

    return true
  },

  checkTimePicker(obj) {
    return (
      this.isTimePickerObj(obj) &&
      obj.hours !== null &&
      obj.hours !== undefined &&
      obj.hours >= 0 &&
      obj.hours <= 24 &&
      obj.minutes !== null &&
      obj.minutes !== undefined &&
      obj.minutes >= 0 &&
      obj.minutes <= 60
    )
  },

  compareTimePickers(start_time, end_time) {
    let start = new Date()
    let end = new Date()

    start.setHours(start_time.hours, start_time.minutes, 0)
    end.setHours(end_time.hours, end_time.minutes, 0)

    return start < end
  },

  time2str(obj) {
    if (!this.checkTimePicker(obj)) return null
    return (obj.hours + '').padStart(2, '0') + ':' + (obj.minutes + '').padStart(2, '0')
  },

  // обязательные vm, title, html, okHandler
  confirm(vm, title, html, okHandler, okTitle, cancelHandler, cancelTitle, params) {
    if (!params) {
      params = {
        title: title,
        hideHeaderClose: false,
        centered: true,
      }
    } else {
      params.title = title
    }
    if (okTitle) {
      params.okTitle = okTitle
    }
    if (cancelTitle) {
      params.cancelTitle = cancelTitle
    }
    vm.$bvModal
      .msgBoxConfirm(vm.$createElement('div', { domProps: { innerHTML: html } }), params)
      .then((ok) => {
        if (ok) {
          okHandler()
        } else if (cancelHandler) {
          cancelHandler()
        }
      })
  },
}

export default Utils
